<template>
<div></div>
</template>
<script>
import abbottAndApi from '@/apis/liff/v2/proj/abbott-and';
import LiffChecker from "@/utils/LiffChecker";

export default {
  mixins: [],
  components: {},
  async mounted() {
    const checker = new LiffChecker(this.$route.params.orgCode);
    this.lineData = await checker.getLineData();
    await this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {},
  methods: {
    async init() {
      try {
        const res = await abbottAndApi.checkMemberByLine(
          this.$route.params.orgCode,
          {
            line_id: this.lineData.profile.userId,
          }
        );
        if (!res.data.is_member) {
          this.$router.push({
            name: 'LiffRegisterIndex',
            params: {
              orgCode: this.$route.params.orgCode,
            }
          });
        } else {
          await abbottAndApi.registerCustomer(
            this.$route.params.orgCode,
          );
        }
      } catch (e) {
        alert(e.response.data.message)
      }
    },
  }
}
</script>

<style lang="scss"></style>
